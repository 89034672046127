import store from "@/store";
import ComfirmBox from "@/components/ComfirmBox";
import CheckBtn from "@/components/Input/CheckBtn";

export default {
  name: 'EditPoint',
  computed: {
    ScheduleList: {
      get() {
        return store.state.schedule.edit.ScheduleList;
      },
      set: (data) => {
        console.log(data[1].start);
      }
    },
    SelectKid: {
      get() {
        return store.state.schedule.edit.selectKid
      }
    },
    timeType: {
      get() {
        return store.state.schedule.edit.timetype
      }
    },
    lang: {
      get() {
        return store.state.Public.lang.schedule;
      }
    },
    langPub: {
      get() {
        return store.state.Public.lang;
      }
    }
  },
  methods: {
    reFreshPointInfo(info) {
      // console.log(info,info.orders.toString() + "_" + info.belong_day);
      this.pointInfo.pointname = info.title;
      this.pointInfo.place_info = info;
      this.pointInfo.selectPoint = info.orders.toString() + "_" + info.belong_day;
      this.pointInfo.method = info.tagMethod;
      this.pointInfo.timetype = info.maintypes;
      this.pointInfo.edittype = "update-point";
      this.pointInfo.stoptime.hour = Math.floor(info.stoptime / 60);
      this.pointInfo.stoptime.min = parseInt(info.stoptime) % 60;
      this.pointInfo.moveTime = {
        movemethod: info.movemethod || 'auto',
        hour: parseInt(info.movetime / 60).toString(),
        min: parseInt(info.movetime % 60).toString()
      }
    },
    parallelEdit(info) {
      this.preInfo = JSON.parse(JSON.stringify(this.pointInfo));
      this.reFreshPointInfo(info);
      this.tabschose = 0;
    },
    parallelDelete(parallel) {
      this.preInfo = JSON.parse(JSON.stringify(this.pointInfo));

    },
    GoMainPoint() {
      this.tabschose = 0;
      this.pointInfo = JSON.parse(JSON.stringify(this.preInfo));
      this.preInfo = {};

    },
    selectedMain(index, data) {
      const tranorders = this.pointInfo.place_info.parallel[index].parallelOrder;
      const newPointmain = this.pointInfo.place_info.parallel[index];
      let olddata = {};
      this.pointInfo.place_info.parallel = this.pointInfo.place_info.parallel.map((item,
        keys) => {
        if (item.parallelOrder == "00") {
          item.parallelOrder = tranorders;
          olddata = item;
          this.pointInfo.place_info.parallelOrder = tranorders;
        } else {
          if (item.parallelOrder == tranorders) {
            item.parallelOrder = "00";
          }
        }
        return item;
      });
      console.log(newPointmain);
      console.log(Object.keys(newPointmain));
      store.dispatch("schedule/ChangeParallel", this.pointInfo.place_info);
      Object.keys(newPointmain).forEach((item, keys) => {
        this.pointInfo.place_info[item] = newPointmain[item];
      });
      this.reFreshPointInfo(this.pointInfo.place_info);
      this.parallelEdit(olddata);

    },
    EditScheduleOk() {
      console.log(this.pointInfo);

      // return ;
      if (this.pointInfo.edittype == "update-point") {
        store.dispatch("schedule/editPoint", this.pointInfo);
      } else {
        store.dispatch("schedule/addSchedule", this.pointInfo);
      }
    },
    AddBoxClose() {
      //  this.pointInfoShow = data;
      this.pointInfo = {
        selectday: 1,
        selectPoint: "",
        method: "",
        pointname: "",
        timetype: [],
        edittype: "", //insert-g , insert-s , update-g , update-s 
        stoptime: {
          hour: "1",
          min: "20"
        },
        place_info: {},
        moveTime: {
          movemethod: "",
          "hour": "0",
          "min": "0"
        }
      };
      this.$emit("update:show", false);
    },
    deletePoint() {
      this.Confirm.name = this.lang.Confirm.deleteTitle;
      this.Confirm.context = "請問是否要移除 : " + "<b class='text-danger'>" + this.pointInfo.pointname + "</b>";
      this.Confirm.show = true;
      this.Confirm.okfunc = () => {
        store.dispatch("schedule/deleteDay", {
          data: this.pointInfo.place_info,
          method: "Point"
        }).then((data) => {
          if (data.data.state) {
            this.pointInfoShow = false;
          };
        });
      }
      console.log(this.pointdata);
    },
    timeTypeSelected(word) {
      const index = this.pointInfo.timetype.indexOf(word);
      if (index > -1) {
        this.pointInfo.timetype.splice(index, 1);
      } else {
        this.pointInfo.timetype.push(word);
      }
    }
  },
  data() {
    return {
      tabschose: 0,

      pointInfoShow: false,
      Confirm: {
        show: false,
        info: {
          name: "",
          context: "",
          okfunc: Function
        }
      },
      preInfo: {

      },
      pointInfo: {
        selectday: 1,
        selectPoint: "",
        method: "",
        pointname: "",
        timetype: [],
        edittype: "", //insert-g , insert-s , update-g , update-s 
        stoptime: {
          hour: "0",
          min: "30"
        },
        place_info: {},
        moveTime: {
          movemethod: "",
          "hour": "0",
          "min": "0"
        }
      }
    }
  },
  watch: {
    show(data) {
      this.preInfo = {};
      this.pointInfoShow = data;
      if (data) {
        this.pointInfo = (this.pointdata) ? JSON.parse(JSON.stringify(this.pointdata)) : this
          .pointInfo;
        this.pointInfo.selectPoint = (this.pointInfo.selectPoint) ? this.pointInfo.selectPoint :
          "";
      }
    },
  },

  props: {
    pointdata: Object,
    show: Boolean,
  },

  components: {
    ComfirmBox,
    CheckBtn
  }
};
