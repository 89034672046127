<template>
  <div>
    <b-modal v-model="pointInfoShow" centered scrollable @ok="EditScheduleOk()"
      @hide="AddBoxClose()" :header-class="['py-2', 'bg-dagee']" :body-class="['p-2']"
      :footer-class="['p-0', 'bg-white']">

      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>{{(pointInfo.edittype == "update-point") ? lang.pointInfoEdit : lang.addInSchedule}}</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>

      </template>

      <template v-slot:default>
        <div class="w-100">
          <b-tabs v-model="tabschose">
            <b-tab class="border border-top-0 p-2" title="景點" active>

              <div class="w-100">
                <div class="w-100 mb-2">
                  <label>◎ 景點名稱</label>
                  <b-input class="px-2" v-model="pointInfo.pointname"></b-input>
                </div>

                <div class="w-100 mb-2">
                  <label>◎ 選擇天數</label>

                  <div class="w-100 d-inline-flex flex-wrap">
                    <div class="mr-2" v-for="(i, index) in ScheduleList.length" :key="index">
                      <div style="min-width: 40px; min-height: 40px"
                        :class='{"bg-info":(pointInfo.selectday == i), "bg-dagee-blue":(pointInfo.selectday != i)}'
                        class="d-flex align-items-center justify-content-center rounded-circle border text-white bg-dagee-blue cur-print"
                        @click="pointInfo.selectday = i; pointInfo.selectPoint = ''">
                        <b>{{i}}</b>
                      </div>
                    </div>

                    <div @click="AddDay(1)">
                      <div style="min-width: 40px; min-height: 40px"
                        class="d-flex align-items-center justify-content-center rounded-circle border text-white bg-dark cur-print">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100" v-if="ScheduleList[pointInfo.selectday-1]">
                  <label class="d-flex align-items-center">
                    <span class="pr-2">◎ 並列</span>
                    <font-awesome-icon size="lg" :icon="['fas', 'angle-right']" />
                    <!-- <font-awesome-icon size="lg" :icon="['fas', 'angle-down']" /> -->
                  </label>
                  <div class="d-inline-flex align-items-center justify-content-between flex-wrap">
                    <div class="mb-2"
                      v-for="(i, index) in ScheduleList[pointInfo.selectday - 1].sub" :key="index">
                      <div class="border text-white bg-dagee-blue px-3 cur-print"
                        :class='{"bg-info":pointInfo.selectPoint==(i.orders + "_" + i.belong_day), "bg-dagee-blue":pointInfo.selectday != i}'
                        @click='pointInfo.selectPoint=(pointInfo.selectPoint != i.orders.toString() + "_" + i.belong_day) ? i.orders + "_" + i.belong_day: ""'>
                        <span class="pms-content-1" style="width: 120px">{{index + 1}}.
                          {{i.title}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100">
                  <label>◎ 景點類別</label>
                  <div class="d-flex align-items-center justify-content-around mb-2">
                    <div v-for="(item, index) in SelectKid" :key="index"
                      @click="pointInfo.method=item.value" style="width: 2.5rem"
                      class="d-flex flex-column align-items-center justify-content-center font-up-2 rounded-pill p-2 cur-print"
                      :class='{"bg-info text-white": (pointInfo.method==item.value), "border border-info text-info": (pointInfo.method!=item.value)}'>
                      <font-awesome-icon :icon="['fas', item.icon]" size="lg" />
                      <span>{{lang.selectkid[item.name]}}</span>
                    </div>
                  </div>

                  <div class="d-flex justify-content-center">
                    <div v-for="(item, index) in timeType" :key="index"
                      class="d-flex align-items-center justify-content-center rounded-circle cur-print mx-2"
                      :class='{"bg-info text-white": (pointInfo.timetype.indexOf(item.value) > -1), "border border-info text-info": (pointInfo.timetype.indexOf(item.value) == -1)}'
                      @click='timeTypeSelected(item.value)' style="width: 2rem; height: 2rem">
                      <span>{{lang.selectkid[item.name]}}</span>
                    </div>
                  </div>

                </div>

                <div class="w-100">
                  <label>◎ 停留時間</label>
                  <div class="w-100 d-flex">
                    <b-input-group size="sm" :append="lang.text.hour">
                      <b-form-select v-model='pointInfo.stoptime.hour'>
                        <option :value="number - 1" v-for="number in 24"> {{number - 1}} </option>
                      </b-form-select>
                    </b-input-group>

                    <b-input-group size="sm" :append="lang.text.min">
                      <b-form-select v-model="pointInfo.stoptime.min">
                        <option :value="number - 1" v-for='number in 60'>{{number - 1}}</option>
                      </b-form-select>
                    </b-input-group>
                    <!-- <div class="w-100">
                      <div class="input-group">
                        <select class="custom-select" v-model="pointInfo.stoptime.hour">
                          <option :value="i-1" v-for='(i, key) in 24' :key="key">{{i-1}}</option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">{{lang.text.hour}}</label>
                        </div>
                        <select class="custom-select" v-model="pointInfo.stoptime.min">
                          <option :value="i-1" v-for="(i,key) in 60" :key="key">{{i-1}}</option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">{{lang.text.min}}</label>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab class="border border-top-0 p-2" title="介紹">
              <div class="w-100">
                <div class="d-flex align-items-center">
                  <b-form-select class="flex-fill">
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled>-- Please select an option --
                      </b-form-select-option>
                    </template>

                    <b-form-select-option value="C"></b-form-select-option>
                    <b-form-select-option value="D">Option D</b-form-select-option>
                  </b-form-select>

                  <button class="btn btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </button>
                </div>
              </div>
            </b-tab>

            <b-tab class="border border-top-0 p-2" title="交通">
              <div class="w-100" v-if='pointInfo.edittype.search("insert") == -1'>
                <label>◎ 移動時間</label>
                <div class="w-100">
                  <div class="w-100 d-flex align-self-center mb-2">
                    <b-form-select size='sm' v-model='pointInfo.moveTime.movemethod'>
                      <option value="auto">{{lang.text.auto}}</option>
                      <option value="setting">{{lang.text.setting}}</option>
                    </b-form-select>
                  </div>

                  <div class="w-100 d-flex align-items-center">
                    <b-input-group size="sm" :append="lang.text.hour">
                      <b-form-select size='sm' v-model='pointInfo.moveTime.hour'
                        :disabled='pointInfo.moveTime.movemethod=="auto"'>
                        <option :value="i-1" v-for='i in 24'>{{i-1}}</option>
                      </b-form-select>
                    </b-input-group>

                    <b-input-group size="sm" :append="lang.text.min">
                      <b-form-select size='sm' v-model='pointInfo.moveTime.min'
                        :disabled='pointInfo.moveTime.movemethod=="auto"'>
                        <option :value="i-1" v-for='i in 60'>{{i-1}}</option>
                      </b-form-select>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title="Go to Main Point" v-if='preInfo.pointname' @click.prevent='GoMainPoint()'>
            </b-tab>

            <b-tab class="border border-top-0 p-2" title="並列" v-if='!preInfo.pointname '>
              <div class="w-100">
                <div class="w-100 d-flex flex-fill pms-parallel-list"
                  v-for="(parallel, index) in pointInfo.place_info.parallel" :key="index">
                  <div class="w-100 d-flex align-items-center justify-content-center">
                    <span class="cur-print pms-text-dagee" @click="selectedMain(index, parallel)">
                      <font-awesome-icon size="lg"
                        :icon="[(parallel.parallelOrder=='00')? 'fas' : 'far', 'check-circle']" />
                    </span>

                    <div class="d-flex align-items-center flex-fill">
                      <span class="px-2">{{index + 1}}</span>

                      <div class="d-flex flex-column">
                        <span>{{parallel.title}}</span>
                        <address class="m-0">
                          {{parallel.address}}
                        </address>
                      </div>
                    </div>

                    <div class="cur-print">
                      <span class="text-info font-up-4 px-1" @click="parallelEdit(parallel)"
                        v-if="parallel.uid != pointInfo.place_info.uid">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>

                      <span class="text-info font-up-4 px-1" @click="tabschose=0"
                        v-if="parallel.uid == pointInfo.place_info.uid">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>

                      <span class="text-danger font-up-4 px-1" @click="parallelDelete(parallel)">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </template>

      <template v-slot:modal-footer="{ ok, cancel }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill d-flex align-items-center justify-content-around">
            <button class="btn btn-sm text-success px-2" @click="ok()">
              <font-awesome-icon :icon="['fas', 'check']" size="lg" />
            </button>

            <button class="btn btn-sm text-danger px-2" @click="cancel()">
              <font-awesome-icon :icon="['fas', 'times']" size="lg" />
            </button>
          </div>

          <button class="btn btn-sm text-danger" @click="deletePoint()"
            v-if='pointInfo.edittype=="update-point"'>
            <font-awesome-icon :icon="['fas', 'trash-alt']" size="lg" />
          </button>
        </div>
      </template>

    </b-modal>

    <ComfirmBox :show.sync='Confirm.show' :context='Confirm.context' :title='Confirm.name'
      :okfunc='Confirm.okfunc'>
    </ComfirmBox>

  </div>
</template>

<script>
  import ctrl from "./js/EditPoint";
  export default ctrl;

</script>
